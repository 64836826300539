import { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";

import Threads from "./pages/Threads";
import Messages from "./pages/Messages";

import axios from "axios";
import GetDataFromQuery from "./utils/CustomHooks/GetDataFromQuery";

function App() {
  // const emails = GetDataFromQuery().get("emails");
  // const [threads, setthreads] = useState([]);
  // useEffect(() => {
  //   if (emails) {
  //     let data = JSON.stringify({
  //       emails: emails.split(","),
  //     });

  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: "https://omotech.test-server.app/api/threads",
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: "OMOTECH",
  //       },
  //       data: data,
  //     };

  //     axios
  //       .request(config)
  //       .then(({ data: { status, threads } }) => {
  //         if (status == 200) {
  //           setthreads(threads);
  //         }
  //       })
  //       .catch((error) => {});
  //   }
  // }, [emails]);
  // console.log(threads);

  return (
    <div className="App">
      {/* {useRoutes([
        {
          path: "/",
          element: (
            <Threads
              {...{
                // threads: new Array(10).fill(threads[0]).filter((el) => el),
                threads: threads,
              }}
            />
          ),
        },
        {
          path: "/:id",
          element: <Messages />,
        },
      ])} */}
      <Messages />
    </div>
  );
}

export default App;
