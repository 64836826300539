const paddNumbers = (num, size) => String(num).padStart(size, "0");
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const dateFormatter = (dateInput, format) => {
  const date = dateInput ? new Date(dateInput) : new Date();

  const hours24 = date.getHours();
  const hours12 = hours24 % 12 || 12;
  const ampm = hours24 >= 12 ? "PM" : "AM";

  const options = {
    YYYY: date.getFullYear(),
    MM: date.getMonth() + 1,
    MMF: monthNames[date.getMonth()],
    MMS: monthShortNames[date.getMonth()],
    DD: date.getDate(),
    HH: hours24,
    hh: hours12,
    mm: date.getMinutes(),
    ss: date.getSeconds(),
    A: ampm,
  };

  return format.replace(/YYYY|MMF|MMS|MM|DD|HH|hh|mm|ss|A/g, (match) =>
    paddNumbers(options[match], 2)
  );
};
