import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import GetDataFromQuery from "../utils/CustomHooks/GetDataFromQuery";
import { dateFormatter } from "../utils/utils";
const curret_date = new Date().valueOf();
const Threads = ({ threads }) => {
  const navigate = useNavigate();
  return (
    <div className="threads_group">
      {threads?.map(({ cc, lastMessage, subject, date, threadId }) => (
        <div className="thread_item" onClick={() => navigate(`/${threadId}`)}>
          <div className="msg_cc">
            {/* {cc.map((el) => (el == "" ? "me" : el.split("@"))).join(", ")} */}
            {cc
              ?.map((el) => {
                if (el.includes("<")) {
                  let t_name = el.split("<")[0].trim();
                  return t_name.toLowerCase().match("omotech") ? "me" : t_name;
                } else {
                  return "";
                }
              })
              .filter((el) => el)
              .join(", ")}
          </div>
          <div className="body">
            <div className="snippet_group">
              <span className="subject">Subject {subject} -</span>
              <span className="lastmessage">{lastMessage}</span>
              <div className="date_time">
                {curret_date - new Date(date).valueOf() > 1 * 60 * 60 * 1000
                  ? dateFormatter(date, "MMS DD")
                  : dateFormatter(date, "hh:mm A")}
              </div>
            </div>
            {/* <div className="attachments">{"NA atttachments ???"}</div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Threads;
